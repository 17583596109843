<template>
  <div>
    <b-card title="Business">
      <b-row>
        <b-col
          cols="12"
          lg="6"
          class="my-1 align-items-start"
        >
          <v-select
            v-model="selectedTenant"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            placeholder="Select Business"
            :options="tenants"
            @input="getTenantModules"
          />
        </b-col>
        <b-col
          md="3"
          sm="2"
          class="my-1"
        />
        <b-col
          md="3"
          sm="2"
          class="my-1"
        />
      </b-row>
    </b-card>
    <b-card
      no-body
      class="w-100"
    >
      <b-overlay
        id="overlay-background"
        :show="loading"
        blur="10px"
        opacity="0.30"
        rounded="sm"
        variant="light"
      >
        <b-table
          striped
          hover
          responsive
          :items="modules"
          :fields="fields"
          show-empty
        >
          <template #cell(is_active)="data">
            <b-badge
              :variant="`light-${data.value == 1 ? 'success' : 'danger'}`"
            >
              {{ data.value == 1 ? 'Yes' : 'No' }}
            </b-badge>
          </template>
          <template #cell(action)="data">
            {{ data.value }}
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              :class="modules.length !== 1 ? 'dropdown-no-scroll':''"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="12"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                @click="setModuleStatus(data.item.id, !data.item.is_active)"
              >
                <feather-icon :icon="data.item.is_active ?'XIcon':'CheckIcon'" />
                {{ data.item.is_active ? 'Deactivate Module':'Activate Module' }}
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BCard,
  BTable,
  BDropdown,
  BDropdownItem,
  BOverlay,
  BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const ClientRepository = RepositoryFactory.get('client')
const ModuleRepository = RepositoryFactory.get('module')

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BTable,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BBadge,
    vSelect,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return {
      loading: false,
      selectedTenant: null,
      tenants: [],
      modules: [],
      fields: [
        {
          key: 'name',
          label: 'Name',
          sortable: false,
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'is_active',
          label: 'Active',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'action',
          label: 'Action',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
    }
  },
  mounted() {
    this.getClientListForDropdown()
  },
  methods: {
    async getClientListForDropdown() {
      this.loading = true
      try {
        const { data } = await ClientRepository.getClientListForDropdown()
        this.tenants = data.data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async getTenantModules() {
      this.loading = true
      try {
        const { data } = await ModuleRepository.getTenantModules(this.selectedTenant.id)
        this.modules = data.data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async setModuleStatus(id, status) {
      this.loading = true
      try {
        const payload = {
          tenant_id: this.selectedTenant.id,
          module_id: id,
          is_active: status,
        }
        const res = await ModuleRepository.updateTenantModule(payload)
        if (res.status === 200) {
          this.showSuccessMessage('Module status updated successfully')
          await this.getTenantModules()
        } else {
          this.showErrorMessage('Module status update failed')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
  },
}
</script>
<style lang="scss">
@import '../@core/scss/vue/libs/vue-select.scss';
</style>
